export default function () {
    const trainingImgList = [
        require('@/assets/activity/training-0-1.jpg'),
        require('@/assets/activity/training-0-2.jpg'),
        require('@/assets/activity/training-0-3.jpg'),
        require('@/assets/activity/training-0-4.jpg'),
    ]
    const trainingArticle =
        '大自然の真理法則に沿った新しい生き方を身に付ける為には、知識ではなく実感が大切です。そこで大自然界では、素晴らしい人生の生き方の実践編として、『大親神大生命』より「純御修行の理」という機会・場をお与え頂いております。\n \
        \n \
        「『大親神大生命』、この世（親物質現象界）の素晴らしい味わいをさせて頂き、ありがとうございます！」と喜び親感謝させて頂く事、これが大自然界の「純御修行の理」です。様々なレクリエーションを通して『大親神大生命』の大いなる生命愛を実感し、親感謝をさせて頂く事で、プラスの生き方を身に付けていきます。\n \
        \n \
        『大親神大生命』は私たち生命の御子に、素晴らしさ・喜び・感動をお与え下さる為にこの世をお創り下さり、それぞれに体を与え、生かして下さっています。ですから何事も、『大親神大生命』に対し奉り感謝して、喜び楽しんで生きることが、私たち「生命の御子」としての本来の生き方なのです。\n \
        \n \
        『大親神大生命』の生命愛を感じさせて頂きますと、物事の感じ方、考え方がどんどん変わっていきます。活かされ生きる生存の喜び・感謝・感動の人生に変わります。ぜひ一緒に体感しましょう！\n \
        \n \
        一年間を通して様々な「純御修行の理」がございます。その一例をご紹介いたします。'
    const trainingActivities = [
        {
            title: '真性映画鑑賞会',
            imgUrl: require('@/assets/activity/training-1.jpg'),
            article:
                '映画には悲劇や喜劇、サスペンスやコメディなど、喜怒哀楽の様々な味わいがあります。「真性映画鑑賞会」では、一本の映画を自分の一つの人生として味わいます。『大親神大生命』より「リアルに喜怒哀楽を感じる事が出来れば、自分がその人生を通ったのと同じ価値がある」というお授けを頂いております。\n \
                \n \
                また、劇中では、戦争、奴隷制度、人種差別、貧困や夫婦・親子の問題など、様々な運命・環境や人生の紆余曲折が描かれます。「このような場合は、どうすれば喜びと感謝の念をもって生きる事が出来るのか？」――様々な事例について『大親神大生命』よりお授けを頂き、大自然の親理に沿ったプラスの生き方を学びます。\n \
                \n \
                「真性映画鑑賞会」では、映画を教材として、様々なテーマについて『大親神大生命』より貴き親理のお授けを頂いております。その一例をご紹介いたします。\n \
                \n \
                <ul class="disc-list-ul">\
                    <li class="disc-list-li">「若草物語」\n \
                        若くして死ぬ人生は不幸なのか？\n \
                        人生劇場の中で演じる役柄と、役者としての自分の価値・実力との違い</li>\
                    <li class="disc-list-li">「A.I.」\n \
                        ロボットやアンドロイドは気持ちを持ち得るのか？\n \
                        なぜ人間は物質を人格化する傾向があるのか？</li>\
                </ul>',
        },
        {
            title: '真性音楽鑑賞会',
            imgUrl: require('@/assets/activity/training-2.jpg'),
            article:
                '屋外に会場を作り、春には満開の桜の下、あるいは、秋には美しい満月を眺めながら、大自然の中で様々な音楽を聴いて、この世（親物質現象界）に活かされ生きる素晴らしさ・感謝・感動を、音楽と共に生命霊（魂・心）に収穫させて頂きます。\n \
                『大親神大生命』より次の様なお授けを頂いております。\n \
                「大自然の中にいると、花が咲いた、海山川がある、月が出た、これらを素晴らしい感動・味わいとして通って行くことができませんか？だから芸術家はできるだけ自然に触れて、自然の素晴らしさを表現するのです。音楽にしても絵画にしても文学にしても、自然が無ければ表現することができません。例えばベートーベンの交響曲第6番“田園”という曲があります。素晴らしい田園の味わいです。しかし、田園が無ければ“田園”の曲は作れません。『大親神大生命』は、皆に生きる素晴らしさや喜び感動を味わいとしてお与え下さるために「親物質現象界」をお創り下さっておられるのです。この世（親物質現象界）は『大親神大生命』がお創りになられた『大親神大生命』の作品なのです。」',
        },
        {
            title: 'お花見',
            imgUrl: require('@/assets/activity/training-3.jpg'),
            article:
                '大自然界の本部「大親所」の敷地内では、春になると桜の木が美しい花を咲かせ、私たちに大自然の素晴らしさ・生命の素晴らしさを教えてくれます。\n \
                『大親神大生命』は桜の木も「生命の家族」の一員として、一生懸命咲いた桜が美しく映えるように、毎年ライトアップして下さいます。「今年も立派に咲いてくれてありがとう、素晴らしいね」と、皆で愛でて、立派に咲いた生命の喜びを、同じ生命の仲間として分かち合います。',
        },
        {
            title: '山野 山菜 純御修行の理',
            imgUrl: require('@/assets/activity/training-4.jpg'),
            article:
                '北陸地方の雪解けが始まると、皆で新潟県の「湯沢親支所」に宿泊させて頂き、山野を歩き回り山菜を採ります。\n \
                『大親神大生命』が現わして下さっておられます、大自然の素晴らしさ、生命力溢れる春の味わいに、毎年本当に、親感謝・真感動の思いでいっぱいになります。\n \
                \n \
                「山野　山菜　純御修行の理」について、次の様な貴き親理のお授けを頂いております：\n \
                『大親神大生命』は一番大きな御心・気持ちをお持ちです。例えば山菜を“ああ！美味しいなあ！『大親神大生命』、ありがとうございます！”と親感謝・喜びで頂けば、『大親神大生命』は、生命の御子らがそんなに喜んだのならば、山菜を出して良かったなと、お喜び下さるのです。ですから同じ頂くのでも、気持ちの理が大切なのです。\n \
                この地球上で、『大親神大生命』が全てに生命をお与え下さり、生かして下さり、食物も全て『大親神大生命』が出して下さっているのです。そこで「『大親神大生命』有り難うございます。こんな素晴らしい味わいです」と、『大親神大生命』に対し奉り「生命の御子」として親感謝を持って食物を頂いたら、『大親神大生命』は大変お喜び下さいます。',
        },
        {
            title: 'タケノコ掘り',
            imgUrl: require('@/assets/activity/training-5.jpg'),
            article:
                '4月～5月には、ご近所の方々のご厚意とご協力を頂いて、タケノコ掘りを行わせて頂いております。\n \
                収穫したタケノコは、料理担当の界員の方々が天ぷらや筍御飯、和え物、お吸い物などに調理させて頂き、理修会後の真和会（会食）などに全員で頂きます。\n \
                筍や山菜を始め、四季折々の食物をお与え頂き、色々な味わいをさせて頂いております事を『大親神大生命』に対し奉り深く深く親感謝申し上げます。',
        },
        {
            title: '真性海水浴',
            imgUrl: require('@/assets/activity/training-6.jpg'),
            article:
                '夏には皆で海水浴に出かけます。子供たちや、時には界員の家族も参加します。「真性海水浴」は、自分たちがただ楽しむレジャーとは異なります。\n \
                海山川があるというのは、「地球をこのように創ろう」という「大自然の大いなる御心」があるからです。大自然の『大親神大生命』は、私たちに素晴らしい思い・生きる味わいを与えようとして下さっておられます。\n \
                「真性海水浴」では、海山川を創り現わして下さっている『大親神大生命』に対し奉り、「素晴らしい味わいをさせて頂きました。『大親神大生命』、このような素晴らしい海山川をお創り下さり、ありがとうございます！」と親感謝をさせて頂き、喜び・楽しみをしっかりと生命霊（魂・心）に収穫させて頂きます。',
        },
        {
            title: '山野 ゴルフ 純御修行の理',
            imgUrl: require('@/assets/activity/training-7.jpg'),
            article:
                'ゴルフはメンタル（精神）のスポーツと言われております。ネガティブな心理状態や精神面の弱さがスコアを台無しにしてしまうのと同様に、不安や怒り、焦り、あるいは投げやりな気持ちは、人生を台無しにしてしまいます。\n \
                「大自然界」では、ゴルフコースを一つの人生として見立てて、様々な人生課題・運命環境をより良くより素晴らしく通り切れるように、気持ちのトレーニングとして行います。バンカーやウォーターハザードなど、どのような状況にあっても自分のマイナスの気持ちに埋没する事なく、常に、『大親神大生命』のお力・お働きを頂き肉体を使わせて頂ける喜び・感謝をもってプレイ出来るよう、努力させて頂きます。\n \
                また、スコアの優劣を競うのではなく、全員が「生命の家族」として共により良いスコアを出せるよう、励まし合い声を掛け合って楽しくプレイします。',
        },
        {
            title: 'テニス 純御修行の理',
            imgUrl: require('@/assets/activity/training-8.jpg'),
            article:
                '青空の下、『大親神大生命』より肉体をお与え頂いた喜び・感謝を感じながら、行わせて頂きます。\n \
                通常のテニスと異なり、「大自然界」の「テニス　純御修行の理」では上手い下手・得意苦手に関係なくチームを作り、ボールのやり取りをお互いの気持ちのやり取りとして、「いかに相手の打ちやすい所にボールを打ち返せるか」という事を一番の目標にテニスを楽しみます。\n \
                「『大親神大生命』、なにとぞよろしくお願い申し上げます」という親感謝の御挨拶を全員で行うと「テニス純御修行の理」のスタートです。テニスコートには、「よろしくお願いします！」「ありがとうございます！」「わあ、変なところに飛んでしまった、ごめんなさい！」「上手、上手！」など、楽しく弾んだ声が響き渡ります。',
        },
        {
            title: '芋煮会',
            imgUrl: require('@/assets/activity/training-9.jpg'),
            article:
                '秋になると、『大親神大生命』のお力・お働きにより育てて頂いた里芋を、農業班が収穫させて頂き「芋煮会」を行います。大自然界では「食べる」という事一つも、大切な「純御修行の理」です。\n \
                『大親神大生命』より\n \
                <ul class="disc-list-ul"> \
                    <li class="disc-list-li"> この世（親物質現象界）で「美味しい芋煮を食べる」という貴い機会と場をお与え頂いたこと、</li>\
                    <li class="disc-list-li"> 芋煮を食べる為の肉体をお与え頂いていること、</li>\
                    <li class="disc-list-li"> 美味しさを感じる心をお与え頂いていること、</li>\
                    <li class="disc-list-li"> 材料となってくれた牛など全生物を生かして下さっていること、</li>\
                    <li class="disc-list-li"> 太陽を照らし雨を降らして里芋やネギを育てて下さったこと…。</li>\
                </ul> \
                『大親神大生命』が行って下さっている全ての事に喜びと感謝をもちまして、皆で楽しく美味しく芋煮を頂きます。',
        },
    ]
    return {
        trainingImgList,
        trainingArticle,
        trainingActivities,
    }
}
