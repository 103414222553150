<template>
    <div id="Training">
        <section id="training-banner" class="banner-section-style">
            <div class="mobile-title hide">
                <h1 class="f20-mb">純御修行の理</h1>
            </div>
            <div
                class="banner"
                :style="
                    addBackgroundImage(
                        require('@/assets/background/background-1.jpg'),
                        require('@/assets/background/background-1-mb.jpg')
                    )
                "
            ></div>
        </section>
        <section id="training-title" class="title-section-style hide-mb">
            <h2 class="title f29">純御修行の理</h2>
        </section>
        <section id="training-intro">
            <div class="wrap w80 f14 f12-mb">
                <div class="image-list">
                    <img
                        v-for="(item, index) of trainingImgList"
                        v-lazy="item"
                        :key="'training-article-image-' + index"
                    />
                </div>
                <p class="p2" v-html="wrap(trainingArticle)"></p>
            </div>
        </section>
        <section id="training-activities">
            <div class="wrap w80">
                <aside class="sticky-container-style f14 hide-mb">
                    <div class="head">
                        <h3>純御修行の理</h3>
                    </div>
                    <ul class="body">
                        <li
                            v-for="(item, index) of trainingActivities"
                            :key="'training-activities-menu-' + index"
                            class="menu-item"
                            @click="
                                scrollToElement('#training-activities-' + index)
                            "
                        >
                            ・{{ item.title }}
                        </li>
                    </ul>
                </aside>
                <main>
                    <article
                        v-for="(item, index) of trainingActivities"
                        :key="'training-activities-' + index"
                        :id="'training-activities-' + index"
                        class="activity-article-style"
                    >
                        <h3 class="f29 f22-mb">{{ item.title }}</h3>
                        <img v-lazy="item.imgUrl" />
                        <p class="f14 f12-mb" v-html="wrap(item.article)"></p>
                    </article>
                </main>
            </div>
        </section>
    </div>
</template>
<script>
import { addBackgroundImage, wrap, scrollToElement } from '@/utils/common.js'
import getTrainingData from '@/data/getTrainingData'
export default {
    name: 'Training',
    setup() {
        let { trainingImgList, trainingArticle, trainingActivities } =
            getTrainingData()
        return {
            addBackgroundImage,
            wrap,
            scrollToElement,
            trainingImgList,
            trainingArticle,
            trainingActivities,
        }
    },
}
</script>
<style lang="scss" scoped>
#training-intro {
    .image-list {
        display: grid;
        grid-template: auto / repeat(4, 1fr);
        margin-bottom: 2em;
        img {
            width: 100%;
            &:last-child {
                padding-right: 0;
            }
        }
    }
    @media screen and (min-width: 768px) {
        background-color: $minorGold;
        padding: 100px 0;
        .wrap {
            background-color: $white;
            padding: 80px 100px;
            box-sizing: border-box;
        }
        .image-list {
            grid-gap: 20px;
        }
    }
    @media screen and(max-width:767px) {
        .wrap {
            padding: 40px 30px;
            box-sizing: border-box;
        }
        .image-list {
            grid-gap: 5px;
        }
    }
}
#training-activities {
    article:first-child {
        border-top: 1px solid $grayB;
    }
    @media screen and (min-width: 768px) {
        background-color: $minorGold;
        .wrap {
            display: flex;
        }
        aside {
            flex: 0 0 220px;
            margin-right: 50px;
        }
        main {
            flex: 1;
        }
    }
}
</style>
